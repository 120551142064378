import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/react-hooks"
import { USER_ID_AND_COMPANY, UPDATE_USER, RESILIENCE_TEST } from "./../gql"
import LayoutContainer from "./../components/layout"
import Throbber from "./../components/throbber"
import loginImage from "./../assets/images/login.svg"
import { Trans, withTranslation } from "react-i18next"
import { APP_ROUTES } from "../constants"

const ChoosePassword = ({ path }) => {
  const [newPassword, setNewPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [invalidPassword, setInvalidPassword] = useState("")
  const [updateUser, { loading, error, data }] = useMutation(UPDATE_USER)
  const { data: questionsData } = useQuery(RESILIENCE_TEST, {
    fetchPolicy: "cache-and-network",
  })

  if (data) {
    // The user has finished the mutation, so the "data" exists.
    // If the user has finished the quiz, send them to the home.
    // Otherwise, send them to the quiz intro page
    if (questionsData && questionsData.resilienceTest.completed) {
      navigate(APP_ROUTES.home)
    } else {
      navigate("/resilience-intro")
    }
  }

  const onSubmit = (event, user, updateUser) => {
    event.preventDefault()
    const { me } = user
    const isPasswordValid = validatePassword()

    if (isPasswordValid) {
      updateUser({
        variables: {
          id: me.id,
          newPassword,
        },
      })
    }
  }

  const validatePassword = () => {
    const passwordsMatch = newPassword === repeatPassword

    if (!passwordsMatch) {
      setInvalidPassword("The repeated password is incorrect.")
      return false
    }
    setInvalidPassword("")
    return true
  }

  const renderForm = (updateUser, updating) => {
    const { loading, error, data } = useQuery(USER_ID_AND_COMPANY)

    if (loading) return <Throbber loadingText="Loading..." relative />
    if (error)
      return (
        <div className="center-align">
          <img className="form__top-image" src={loginImage} alt="Login" />
          <p className="text">{error.message}</p>
          <Link className="button" to="/login">
            <Trans i18nKey="choosepassword.loginbutton" />
          </Link>
        </div>
      )

    return (
      <form
        className="form margin--top-40"
        onSubmit={event => onSubmit(event, data, updateUser, loading)}
        noValidate
      >
        <div className="form__image-wrapper">
          <img className="form__top-image" src={loginImage} alt="Login" />
        </div>
        <label className="sr-only" htmlFor="new-password">
          <Trans i18nKey="choosepassword.password_label" />
        </label>
        <input
          id="new-password"
          type="password"
          placeholder="Your password"
          className="form__text-input"
          onChange={e => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <label className="sr-only" htmlFor="repeat-password">
          <Trans i18nKey="choosepassword.password_repeat_label" />
        </label>
        <input
          id="repeat-password"
          type="password"
          placeholder="Repeat password"
          className="form__text-input"
          onChange={e => setRepeatPassword(e.target.value)}
          value={repeatPassword}
        />
        {invalidPassword && (
          <div className="center-align">
            <p className="feature-text no-margin">{invalidPassword}</p>
          </div>
        )}
        <div className="center-align margin--top-40">
          <input
            type="submit"
            className="button"
            value="Confirm"
            disabled={updating}
          />
        </div>
      </form>
    )
  }

  return (
    <LayoutContainer seoTitle="Choose password" path={path}>
      <div className="row">
        <div className="col l8 offset-l2 s12">
          <div className="center-align margin--bottom-16">
            <h1 className="big-title uppercase no-margin">
              <Trans i18nKey="choosepassword.main_title" />
            </h1>
          </div>
        </div>
        <div className="col l4 offset-l4 m8 offset-m2 s12">
          {renderForm(updateUser, loading)}
          <div className="center-align">
            {loading && <Throbber loadingText="Loading..." relative />}
            {error && <p className="text">{error.message}</p>}
          </div>
        </div>
      </div>
    </LayoutContainer>
  )
}

export default withTranslation()(ChoosePassword)
